import { OmniElement, OmniStyle, OmniIcon, html, nothing } from 'omni-ui';
import { om2FormModal, om2alert, om2confirm, Om2Table } from 'omni-campaign-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { showLoader } from './helpers/util.js';
import { api } from './helpers/api.js';

OmniStyle.register();
OmniIcon.register();
Om2Table.register();

export default class ModuleAssistConfig extends OmniAppContainerMixin(
  OmniElement
) {
  static get properties() {
    return {
      moduleId: this.routeParamProperty({ name: 'moduleId' }),

      // Internal
      module: { type: Object },
      assistFields: { state: true },
    };
  }

  async updated(changed) {
    if (changed.has('moduleId') && this.moduleId) {
      this.module = await showLoader(this, api.getModule(this.moduleId));
      this.assistFields = this.module.assist_fields;
      this.dispatchNewEvent('breadcrumb-refresh');
    }
  }

  get breadcrumb() {
    return {
      label: `${this.module?.name ?? ''} - Assist Fields`,
      link: this.module?.uuid_module
        ? `module/${this.module.uuid_module}/assist`
        : null,
      toolbarActions: this.enabledAssistFields?.length
        ? {
            Add: this.create,
          }
        : {},
    };
  }

  async refresh() {
    this.assistFields = await api.listModuleAssistFields(
      this.module.uuid_module
    );
  }

  get isDynamicForm() {
    return this.module?.module_type === 'form';
  }

  get enabledAssistFields() {
    if (!this.module) return [];
    if (this.isDynamicForm)
      return Object.entries(this.module.data_structure.schema || {})
        .filter(([, config]) => config['omni-assist'] === 'autofill')
        .map(([field]) => field);
    return this.module.module_template.assist_fields || [];
  }

  addEnumLabels(fields) {
    if (!this.isDynamicForm) return fields;
    return fields.map(field => {
      const title = this.module.data_structure?.schema?.[field]?.title || '';
      return { id: field, name: `"${title}" [${field}]` };
    });
  }

  create = () => {
    // Get the list of assist fields for the current module that haven't been added
    const unused = this.enabledAssistFields.filter(
      fieldId => !this.assistFields.some(field => field.field_id === fieldId)
    );

    if (unused.length === 0) {
      om2alert('All assist fields currently added.', {
        type: 'warning',
        toast: true,
      });
      return;
    }

    om2FormModal({
      title: 'Add Omni Assist Enabled Field',
      data: {},
      onSubmit: async data => {
        await api.addModuleAssistField(
          this.module.uuid_module,
          data.field_id,
          data
        );
        await this.refresh();
      },
      onSuccess: () => {
        om2alert('Your prompt has been added', {
          type: 'success',
          toast: true,
        });
      },
      schema: {
        field_id: {
          title: 'Assist Field',
          required: true,
          type: 'string',
          enum: this.addEnumLabels(unused),
        },
        prompt: {
          title: 'Prompt',
          type: 'string',
          multiline: true,
          required: true,
          formModal: { fullWidth: true },
        },
      },
    });
  };

  delete(type) {
    om2confirm(
      `Permanently delete assist configuration for "${type.field_id}"?`,
      {
        callback: async () => {
          await api.deleteModuleAssistField(
            this.module.uuid_module,
            type.field_id
          );
          await this.refresh();
          om2alert('Your prompt has been deleted', {
            type: 'success',
            toast: true,
          });
        },
        type: 'warning',
      }
    );
  }

  edit(type) {
    om2FormModal({
      title: 'Edit Omni Assist Enabled Field',
      data: type,
      onSubmit: async data => {
        await api.editModuleAssistField(
          this.module.uuid_module,
          data.field_id,
          data
        );
        await this.refresh();
      },
      onSuccess: () => {
        om2alert('Your prompt has been updated', {
          type: 'success',
          toast: true,
        });
      },
      schema: {
        field_id: {
          title: 'Assist Field',
          type: 'string',
          disabled: true,
        },
        prompt: {
          title: 'Prompt',
          type: 'string',
          required: true,
          multiline: true,
          formModal: { fullWidth: true },
        },
      },
    });
  }

  async openAgentPage(config) {
    const url = `module/${this.module.uuid_module}/assist/${config.field_id}/agent`;
    this.navigateTo(url);
  }

  get #emptyTableTemplate() {
    return this.enabledAssistFields?.length
      ? html`
          <div slot="body" class="p-5 has-text-centered has-text-shark">
            <i>Click "Add" to customize a prompt for a field</i>
          </div>
        `
      : html`
          <div slot="body" class="p-5 has-text-centered has-text-shark">
            <i>
              There are no assist fields currently enabled for this module.
              ${this.isDynamicForm
                ? html`You can enable fields in the
                    <a
                      href="#"
                      @contextmenu=${e => e.preventDefault()}
                      @keyup=${e => e.key === 'Enter' && e.target.click()}
                      @click=${e => {
                        e.preventDefault();
                        this.navigateTo(
                          `module/${this.module.uuid_module}/builder`
                        );
                      }}
                      >form builder</a
                    >`
                : nothing}
            </i>
          </div>
        `;
  }

  renderAssistConfig() {
    if (!this.module) return nothing;

    return html`
      <omni-style>
        <om2-table
          autosort
          autotooltip
          shadowed
          .search=${['field_id', 'prompt']}
          .columns=${[
            {
              label: 'assist field',
              key: 'field_id',
              isSortable: true,
              isMain: true,
            },
            {
              label: 'prompt',
              key: 'prompt',
              isSortable: true,
            },
            {
              label: 'actions',
              passthrough: true,
              template: assistConfig => html`
                <td>
                  <button
                    @click="${() => this.edit(assistConfig)}"
                    class="icon"
                    title="Edit"
                  >
                    <omni-icon icon-id="omni:interactive:edit"></omni-icon>
                  </button>

                  <button
                    @click="${() => this.openAgentPage(assistConfig)}"
                    class="icon ml-4 p-0 pl-1 pr-1"
                    title="Agents"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      version="1.0"
                      viewBox="0 0 289 244"
                      style="fill: var(--color-almost-black)"
                    >
                      <path
                        d="M111 41c-8 5-9 8-14 33l-5 22-13 3c-67 10-61 34 11 42 96 12 203-11 157-33-9-4-38-11-47-11h-3l-3-19c-5-26-6-29-11-33-6-6-11-7-16-5a45 45 0 0 1-43 1c-5-3-8-3-13 0zm17 15c11 3 23 3 33 0 15-6 16-5 21 20l5 22 11 11c8 0 41 8 41 10 0 6-44 13-83 14-41 1-72-2-99-10-12-4-7-7 20-12 13-2 19-4 17-5l4-5c5-6 5-6 9-25 5-25 5-26 21-20zm-45 98c-9 4 3 34 16 40 17 9 34 2 40-16 4-12 7-11 12 0 5 16 18 23 34 19 16-5 32-39 21-43-8-3-31-1-51 5l-10 2-10-2c-22-6-45-8-52-5zm35 14c13 2 13 3 9 10-7 12-23 11-31-4-7-10-4-11 22-6zm78-1c0 15-22 25-31 15-5-6-7-11-3-12l23-4c10-1 11-1 11 1z"
                      />
                    </svg>
                  </button>

                  <button
                    @click=${() => this.delete(assistConfig)}
                    class="icon ml-4"
                    title="Delete"
                  >
                    <omni-icon icon-id="omni:interactive:delete"></omni-icon>
                  </button>
                </td>
              `,
            },
          ]}
          .data="${this.assistFields ?? []}"
        >
          <span slot="header-start">Omni Assist Enabled Fields</span>
          ${!this.assistFields?.length ? this.#emptyTableTemplate : nothing}
        </om2-table>
      </omni-style>
    `;
  }

  render() {
    return html`<slot>${this.renderAssistConfig()}</slot>`;
  }
}

customElements.define('module-assist-config', ModuleAssistConfig);
