import { OmniElement, OmniStyle, OmniIcon, html, css, styleMap } from 'omni-ui';

OmniStyle.register();
OmniIcon.register();

/**
 * Class for Empathy Map Element
 * Replicates campaign-empathy-map design on campaign overview page for purposes of previewing styles
 * @extends OmniElement
 */
export default class EmpathyMap extends OmniElement {
  /**
   * Empathy Map styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        .empathy-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 2px 2px;
          align-content: center;
        }
        .empathy-box {
          height: 13.357rem;
          flex: 1 1 calc(50% - 2px);
          padding: 1rem;
        }
        .empathy-box:nth-child(even) {
          text-align: right;
        }
        .omni-style-flex {
          flex-grow: 1;
        }
      `,
    ];
  }

  /**
   * Renders individual empathy map quadrant with themable background color
   * @param {string} color - RGBA color value in string format
   */
  _renderEmpathyBox = color =>
    html` <div
      class="empathy-box"
      style=${styleMap({
        background: color,
      })}
    >
      <button
        part="toggle-open-btn"
        class="icon secondary open-btn"
        id="viz4-btn"
      >
        <omni-icon
          part="toggle-open-btn-icon"
          icon-id="omni:interactive:add"
        ></omni-icon>
      </button>
    </div>`;

  /**
   * Calls {@link _renderEmpathyBox} for each quadrant of empathy map
   */
  _renderEmpathyBoxes = () => {
    const boxColors = [
      'rgba(var(--rgb-primary), 0.1)',
      'rgba(var(--rgb-primary), 0.4)',
      'rgba(var(--rgb-primary), 0.2)',
      'rgba(var(--rgb-primary), 0.3)',
    ];
    return boxColors.map(color => this._renderEmpathyBox(color));
  };

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    return html` <omni-style class="omni-style-flex">
      <div class="empathy-container">${this._renderEmpathyBoxes()}</div>
    </omni-style>`;
  }
}

customElements.define('empathy-map', EmpathyMap);
