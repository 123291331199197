import { OmniElement, OmniStyle, OmniIcon, html, nothing } from 'omni-ui';
import { Om2Table, om2FormModal, om2confirm, om2alert } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import { showLoader, hasRole } from './helpers/util.js';
import { OmniAppContainerMixin } from 'omni-app-container';

OmniStyle.register();
OmniIcon.register();
Om2Table.register();

export default class WorkflowList extends OmniAppContainerMixin(OmniElement) {
  /**
   * Custom Themes Preview Lit Properties
   * @property {Object} data - Array of workflows
   * @property {Boolean} showAll - Determines whether inactive ft are shown
   */
  static get properties() {
    return {
      data: { type: Array },
      showAll: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.data = [];
    this.showAll = false;
  }

  // Load the list of workflows after the first render()
  firstUpdated() {
    this.reload();
  }

  get breadcrumb() {
    return {
      label: 'Workflows',
      link: 'workflow',
      toolbarActions: { Add: () => this.create() },
    };
  }

  async reload() {
    this.data = await showLoader(
      this,
      api.listFrameworkTemplates({
        fields: ['uuid_framework_template', 'name', 'description', 'active'],
      })
    );
  }

  async getClients() {
    return await api.listClients();
  }

  async reloadNew(id) {
    // Reload and then navigate to newly created object
    this.reload().then(() => {
      const ft = this.data.find(
        template => template.uuid_framework_template === id
      );
      this.manageTemplate(ft);
    });
  }

  getWorkflowSchema = clientOptions => ({
    name: {
      title: 'Name',
      type: 'string',
      required: true,
    },
    description: {
      title: 'Description',
      type: 'string',
      required: true,
    },
    overview_layout: {
      title: 'Overview Layout',
      type: 'dropdown',
      enum: ['Grid', 'Accordion'],
      default: 'Grid',
    },
    step_lock_timeout: {
      title: 'Step-Lock Timeout (mins)',
      type: 'number',
      min: 0,
      multipleOf: 1,
      default: 10,
      disabled: !hasRole('super admin'),
    },
    active: {
      title: 'Active',
      type: 'boolean',
      default: true,
    },
    assigned_clients: {
      type: 'string',
      enum: clientOptions,
      twopane: true,
      leftPaneLabel: 'Available clients',
      rightPaneLabel: 'Assigned clients',
      leftPanePlaceholder: html`Use search to display <br />
        a list of clients to search from`,
      rightPanePlaceholder: html`No clients added. <br />
        Search and select clients first.`,
      searchPlaceholder: 'Search by client name',
      default: [],
      formModal: { fullWidth: true },
    },
  });

  async create() {
    // Create Template
    om2FormModal({
      title: 'Create Workflow',
      data: {},
      onSubmit: data =>
        api.createFrameworkTemplate({
          ...data,
          assigned_clients: data.assigned_clients.map(c => ({
            uuid_client: c,
          })),
        }),
      onSuccess: json => this.reloadNew(json.uuid),
      schema: new Promise(resolve => {
        this.getClients().then(clientOptions =>
          resolve(this.getWorkflowSchema(clientOptions))
        );
      }),
    });
  }

  manageTemplate(template) {
    this.navigateTo(`workflow/${template.uuid_framework_template}`);
  }

  async editTemplate({ uuid_framework_template: id }) {
    const clientOptions = await showLoader(this, this.getClients());
    const template = await showLoader(this, api.getFrameworkTemplate(id));

    const data = structuredClone(template);
    data.assigned_clients = (data.assigned_clients || [])
      .filter(assigned =>
        clientOptions.some(option => option.id === assigned.uuid_client)
      )
      .map(c => c.uuid_client);

    om2FormModal({
      title: 'Edit Workflow',
      data,
      onlySubmitChanges: true,
      onSubmit: submitData => {
        if (submitData.assigned_clients) {
          // eslint-disable-next-line no-param-reassign
          submitData.assigned_clients = submitData.assigned_clients.map(c => ({
            uuid_client: c,
          }));
        }

        return api.updateFrameworkTemplate(
          template.uuid_framework_template,
          submitData
        );
      },
      onSuccess: () => this.reload(),
      schema: this.getWorkflowSchema(clientOptions),
    });
  }

  async cloneTemplate({ uuid_framework_template: id }) {
    const clientOptions = await showLoader(this, this.getClients());
    const template = await showLoader(this, api.getFrameworkTemplate(id));

    om2FormModal({
      title: 'Clone Workflow',
      data: { name: `${template.name} [cloned]`, active: false },
      onSubmit: data => {
        if (!data.name) throw new Error('Name is required');
        return api.cloneFrameworkTemplate(template.uuid_framework_template, {
          ...data,
          assigned_clients: data.assigned_clients.length
            ? data.assigned_clients.map(c => ({
                uuid_client: c,
              }))
            : [],
        });
      },
      onSuccess: json => this.reloadNew(json.uuid),
      schema: {
        name: {
          title: 'Name',
          type: 'string',
          required: true,
        },
        active: {
          title: 'Active',
          type: 'boolean',
        },
        assigned_clients: {
          title: 'Assigned Clients',
          type: 'string',
          enum: clientOptions,
          isMulti: true,
          hasSearch: true,
          default: [],
          formModal: { fullWidth: true },
        },
      },
    });
  }

  deleteTemplate = async template => {
    try {
      await api.deleteFrameworkTemplate(template.uuid_framework_template);
      om2alert(`Framework template successfully deleted`, {
        type: 'success',
        toast: true,
        closeTimeout: 5000,
      });
      this.reload();
    } catch (e) {
      if (e.json.status === 409) {
        om2confirm(
          html`
            <p class="mb-5">
              Cannot delete
              <strong
                style="background-color: rgba(var(--rgb-core-light), 0.25); text-transform: uppercase;"
                >${template.name}</strong
              >
              because there are non-archived campaigns associated with this
              workflow. See table below for more details.
            </p>
            <omni-style>
              <om2-table
                autosort
                autotooltip
                .search=${['campaign_name', 'uuid_campaign', 'uuid_client']}
                .columns=${[
                  {
                    label: 'campaign name',
                    key: 'name',
                    isSortable: true,
                    isMain: true,
                  },
                  {
                    label: 'campaign id',
                    key: 'uuid_campaign',
                    isSortable: true,
                  },
                  {
                    label: 'client id',
                    key: 'guid_client',
                    isSortable: true,
                  },
                ]}
                .data="${e.json.active_campaigns}"
                .keyFn=${({ uuid_framework_template: id }) => id}
              >
                <span class="has-text-dark" slot="header-start"
                  >Non-Archived Campaigns</span
                >
              </om2-table>
            </omni-style>
          `,
          {
            title: 'Cannot Delete Workflow',
            type: 'danger',
            modalWidth: 'auto',
          }
        );
      } else {
        om2alert(`An error occurred: ${e.message}`, { type: 'danger' });
      }
    }
  };

  renderWorkflows() {
    const activeWorkflow = this.showAll
      ? this.data
      : this.data.filter(ft => ft.active);

    return html`
      <omni-style>
        <om2-table
          shadowed
          autosort
          autotooltip
          .search=${['name', 'uuid_framework_template']}
          .columns=${[
            {
              label: 'name',
              key: 'name',
              isSortable: true,
              isMain: true,
            },
            {
              label: 'id',
              key: 'uuid_framework_template',
              isSortable: true,
            },
            {
              label: 'description',
              key: 'description',
              isSortable: true,
            },
            {
              label: 'active',
              key: 'active',
              isSortable: true,
              template: val =>
                html`<td>
                  ${val
                    ? html`<omni-icon
                        icon-id="omni:informative:check"
                      ></omni-icon>`
                    : ''}
                </td>`,
            },
            {
              label: 'actions',
              passthrough: true,
              template: template => html`
                <td>
                  <button
                    @click="${() => this.editTemplate(template)}"
                    class="icon"
                    title="Edit"
                  >
                    <omni-icon icon-id="omni:interactive:edit"></omni-icon>
                  </button>
                  <button
                    @click="${() => this.cloneTemplate(template)}"
                    class="icon ml-4"
                    title="Clone"
                  >
                    <omni-icon icon-id="omni:interactive:copy"></omni-icon>
                  </button>
                  <button
                    @click="${() => this.manageTemplate(template)}"
                    class="icon ml-4"
                    title="Manage"
                  >
                    <omni-icon icon-id="omni:interactive:launch"></omni-icon>
                  </button>
                  ${hasRole('super admin')
                    ? html` <omni-tooltip
                        class="ml-4"
                        ?disabled=${!template.active}
                      >
                        <div slot="content">Cannot delete active template</div>
                        <button
                          @click="${() =>
                            om2confirm(
                              'Are you sure you want to delete this framework template and all associated campaigns? This action is permanent and cannot be undone.',
                              {
                                callback: () => this.deleteTemplate(template),
                                type: 'warning',
                              }
                            )}"
                          class="icon"
                          title="Manage"
                          ?disabled=${template.active}
                        >
                          <omni-icon
                            icon-id="omni:interactive:delete"
                          ></omni-icon>
                        </button>
                      </omni-tooltip>`
                    : nothing}
                </td>
              `,
            },
          ]}
          .data="${activeWorkflow}"
          .keyFn=${({ uuid_framework_template: id }) => id}
        >
          <span slot="header-start">Workflows</span>
          <span slot="header-end" class="is-flex is-align-items-center">
            <omni-switch
              .checked=${this.showAll}
              @change=${() => {
                this.showAll = !this.showAll;
              }}
            ></omni-switch>
            <span class="ml-2">Show All</span>
          </span>
        </om2-table>
      </omni-style>
    `;
  }

  render() {
    return html`<slot>${this.renderWorkflows()}</slot>`;
  }
}

customElements.define('workflow-list', WorkflowList);
