import { html } from 'omni-ui';
import { om2alert, om2confirm, Om2FormBuilder } from 'omni-campaign-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { api } from './helpers/api.js';
import { hasRole, showLoader } from './helpers/util.js';

Om2FormBuilder.register();

/**
 * Customized version of Om2FormBuilder with specific needs of the admin.
 * @extends Om2FormBuilder
 */
export default class DynamicFormBuilder extends OmniAppContainerMixin(
  Om2FormBuilder
) {
  constructor() {
    super();
    this.enableSharedFields = true; // Enable this in Om2FormBuilder
    this.filterFieldSchema = hasRole('super admin')
      ? null
      : hasRole('administrator')
        ? (inputField, setting, isAdd) =>
            this.applyFilters(inputField, setting, isAdd, [
              'readonly',
              'disabled',
            ])
        : (inputField, setting, isAdd) =>
            this.applyFilters(inputField, setting, isAdd, [
              'disabled',
              'readonly',
              'class',
            ]);
    this.addEventListener('om2-form-builder-cancel', e => {
      e.stopPropagation();
      this.#warnIfChanges(() => this.dispatchNewEvent('breadcrumb-pop'));
    });
  }

  static get properties() {
    return {
      moduleId: this.routeParamProperty({ name: 'moduleId' }),
    };
  }

  async updated(changed) {
    if (changed.has('moduleId') && this.moduleId) {
      this.module = await showLoader(this, api.getModule(this.moduleId));
      this.uiSchema = this.module?.data_structure?.uiSchema ?? [];
      this.schema = this.module?.data_structure?.schema ?? {};
      this.dispatchNewEvent('breadcrumb-refresh');

      // Wait for the property updates to carry through then update original properties
      // to reset "hasUnsavedChanges" to false.
      await this.updateComplete;
      this.schemaOriginal = structuredClone(this.schema);
      this.uiSchemaOriginal = structuredClone(this.uiSchema);
    }
  }

  get breadcrumb() {
    return {
      label: this.module?.name,
      // link: No children routes so don't need a link for now
      toolbarActions: [
        [
          html`
            <omni-tooltip-next slot="end">
              <div slot="content">Omni Assist Fields</div>
              <button
                slot="invoker"
                class="icon large"
                @click=${() => {
                  this.navigateToAssist();
                }}
              >
                <omni-icon icon-id="omni:brand:omni"></omni-icon>
              </button>
            </omni-tooltip-next>
            <div slot="end" class="toolbar-divider"></div>
          `,
        ],
        [this.preview ? 'Builder' : 'Preview', this.toggleToolbarAction],
      ],
    };
  }

  /**
   * Helper function to filter and hide + disable fields from dynamic form builder.
   *
   * @param {Object} inputField- The input field type to be filtered
   * @param {boolean} setting - The setting in the modal to filter
   * @param {boolean} isAdd - Flag indicating whether this is an add or edit modal
   * @returns {Object} The updated schema object
   */
  // eslint-disable-next-line no-unused-vars
  applyFilters = (inputField, setting, isAdd, filterSettings) =>
    !filterSettings.includes(setting);

  toggleToolbarAction = preview => {
    this.preview = preview ?? !this.preview;
  };

  navigateToAssist = () => {
    this.#warnIfChanges(
      () => this.navigateTo(`module/${this.moduleId}/assist`),
      "Please save your changes before navigating to Assist. If you don't, your updates will not be reflected, and you won't see the latest information in Assist."
    );
  };

  #warnIfChanges(action, message) {
    if (!this.hasUnsavedChanges) {
      action();
      return;
    }

    om2confirm(
      message ||
        'If you continue without saving, your changes will be lost. How would you like to proceed?',
      {
        title: 'You have unsaved changes',
        type: 'warning',
        callback: [
          {
            label: 'Continue',
            func: action,
          },
          {
            label: 'Save',
            func: async () => {
              await this._triggerSave();
              action();
            },
          },
        ],
      }
    );
  }

  /**
   * Implement form save with a backend api call to update the module.
   * Exception handling not needed as that will be done in the calling function.
   *
   * @async
   */
  async save() {
    const copy = structuredClone(this.module);
    copy.data_structure = {
      uiSchema: this.uiSchema,
      schema: this.schema,
    };
    await api.updateModule(copy.uuid_module, copy);
    om2alert('Module saved', { toast: true, type: 'success' });
  }
}

customElements.define('dynamic-form-builder', DynamicFormBuilder);
