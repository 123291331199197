import {
  OmniElement,
  OmniStyle,
  OmniToolbar,
  OmniLoadingIndicator,
  html,
  css,
  nothing,
  createRef,
  ref,
} from 'omni-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { api } from './helpers/api.js';
import { hasRole } from './helpers/util.js';
import { CampaignSingletonAPI } from 'omni-campaign/src/services/singleton-api.js';

OmniStyle.register();
OmniToolbar.register();
OmniLoadingIndicator.register();

export default class AppLayout extends OmniAppContainerMixin(OmniElement) {
  static get properties() {
    return {
      location: { type: Object }, // updated by vaadin router
      env: this.contextProperty({ name: 'env' }),
      token: this.contextProperty({ name: 'token' }),
      tab: this.routeParamProperty({ name: 'tab' }),
      waitingFor: { state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        omni-loading-indicator.small::part(svg) {
          width: 20px;
        }
        omni-loading-indicator.big {
          margin-top: calc(100px + var(--omni-app-layout-header-height));
          position: fixed;
          z-index: 100;
          width: -webkit-fill-available;
          height: 83px;
        }
        .under-loading-indicator {
          opacity: 0.5;
          pointer-events: none;
          user-select: none;
        }
        omni-toolbar {
          z-index: 1;
          width: calc(100% - var(--omni-app-layout-drawer-current-width));
          position: fixed;
        }
        .toolbar-spacer {
          height: 80px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.slotRef = createRef();
    this.waitingFor = new Set();
    this.addEventListener('app-layout-loading', e => {
      if (e.detail.id) this.waitingFor.add(e.detail.id);
      this.requestUpdate();
    });
    this.addEventListener('app-layout-ready', e => {
      this.waitingFor.delete(e.detail.id);
      this.requestUpdate();
    });
  }

  get isLoading() {
    return this.waitingFor.size !== 0;
  }

  updated(changedProperties) {
    if (
      (changedProperties.has('env') || changedProperties.has('token')) &&
      this.token &&
      this.env
    ) {
      // Initialize/update api for use across the admin
      api.init(this.env, this.token);
      CampaignSingletonAPI.init(this.env, this.token);
    }
  }

  static routes = baseURL => [
    {
      path: '/',
      redirect: `${baseURL}/workflow`,
    },
    {
      path: '/:tab(workflow)',
      action: () => import('./workflow-list.js'),
      component: 'workflow-list',
      children: [
        {
          path: '/:workflowId',
          action: () => import('./workflow-view.js'),
          component: 'workflow-view',
          children: [
            {
              path: '/theme/:clientId',
              action: () => import('./custom-themes/custom-themes.js'),
              component: 'custom-themes',
            },
            {
              path: '/:stageId',
              action: () => import('./workflow-stage.js'),
              component: 'workflow-stage',
              children: [
                {
                  path: '/:stepId',
                  action: () => import('./workflow-step.js'),
                  component: 'workflow-step',
                  children: [
                    {
                      path: '/module/:moduleId',
                      action: async () => import('./module-list.js'),
                      component: 'module-list',
                      children: [
                        /* These two routes are currently unused */
                        {
                          path: '/builder',
                          action: () => import('./dynamic-form-builder.js'),
                          component: 'dynamic-form-builder',
                        },
                        {
                          path: '/assist',
                          action: () => import('./module-assist-config.js'),
                          component: 'module-assist-config',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/:tab(module)',
      action: () => import('./module-list.js'),
      component: 'module-list',
      children: [
        {
          path: '/:moduleId/builder',
          action: async () => {
            await import('./module-list.js');
            await import('./dynamic-form-builder.js');
          },
          component: 'dynamic-form-builder',
        },
        {
          path: '/:moduleId/assist',
          action: async () => {
            await import('./module-list.js');
            await import('./module-assist-config.js');
          },
          component: 'module-assist-config',
          children: [
            {
              path: '/:fieldId/agent',
              action: async () => {
                await import('./module-assist-agent.js');
              },
              component: 'module-assist-agent',
            },
          ],
        },
        {
          path: '/:moduleId/workflows',
          action: async () => {
            await import('./module-workflow-list.js');
          },
          component: 'module-workflow-list',
        },
      ],
    },
    {
      path: '/:tab(content-type)',
      action: () => import('./content-type-list.js'),
      component: 'content-type-list',
    },
    {
      path: '/:tab(module-template)',
      action: () => import('./module-templates.js'),
      component: 'module-templates',
    },
    {
      path: '/:tab(history)',
      action: () => import('./history-table.js'),
      component: 'history-table',
    },
  ];

  shouldUpdate(changedProperties) {
    if (changedProperties.has('location')) {
      // Unfortunately when "this.location" is changed, the dom has not yet been fully updated by the vaadin router (old dom elements are still present). Simply waiting for the next event cycle allows updating breadcrumbs correctly as the router has fully removed old dom elements.
      setTimeout(() => this.requestUpdate());
      return false;
    }
    return true;
  }

  /**
   * Walk through child elements looking at the nesting of child components. Each
   * component that should be in our breadcrumbs will define a "breadcrumb" property
   * with metadata about that component.
   *
   * @returns {Array} List of breadcrumb objects (label, link, toolbarActions)
   */
  get breadcrumbs() {
    const nodes = [];
    for (
      let node = this.slotRef.value?.assignedElements()?.[0];
      node?.breadcrumb;
      node = node.firstElementChild
    ) {
      nodes.push(node.breadcrumb);
    }
    return nodes;
  }

  #renderBreadCrumbs() {
    const nodes = this.breadcrumbs;
    if (nodes.length <= 1) return nothing;

    return html`
      <nav
        class="mb-1 breadcrumb has-succeeds-separator is-medium mb-5 ml-5"
        aria-label="breadcrumbs"
      >
        <ul>
          ${nodes.map(
            (node, index) => html`
              <li class="${index === nodes.length - 1 ? 'is-active' : ''}">
                <a
                  href="#"
                  @contextmenu=${e => e.preventDefault()}
                  @click=${e => {
                    e.preventDefault();
                    this.navigateTo(node.link);
                  }}
                >
                  ${node.label === undefined
                    ? html`<omni-loading-indicator
                        class="small"
                      ></omni-loading-indicator>`
                    : html`<span>${node.label}</span>`}
                </a>
              </li>
            `
          )}
        </ul>
      </nav>
    `;
  }

  #breadCrumbPop = () => {
    const nodes = this.breadcrumbs;
    if (nodes.length > 1) this.navigateTo(nodes[nodes.length - 2].link);
  };

  #getToolbarActions() {
    const nodes = this.breadcrumbs;
    return nodes[nodes.length - 1]?.toolbarActions ?? {};
  }

  get #toolbarActionsTemplate() {
    // This is clunky -- originally the button in the top right was only for text
    // buttons but now we have 1 example of needing an icon button and a text button
    // side-by-side with a divider between them (form builder). This will work for
    // now but if we need more features here we should refactor this.
    const actions = this.#getToolbarActions();
    return (Array.isArray(actions) ? actions : Object.entries(actions)).map(
      ([label, callback]) => {
        if (typeof label === 'object') return label;

        return html`<button
          slot="end"
          class="secondary large"
          @click=${() => {
            callback();
            this.requestUpdate();
          }}
        >
          ${label}
        </button>`;
      }
    );
  }

  render() {
    const tabs = [
      { name: 'Workflows', id: 'workflow' },
      { name: 'Module Library', id: 'module' },
      { name: 'History', id: 'history' },
    ];

    // check for superadmin to enable content type tab
    if (hasRole('super admin')) {
      tabs.push(
        { name: 'Content Types', id: 'content-type' },
        { name: 'Module Templates', id: 'module-template' }
      );
    }
    const currentTab = tabs.find(tab => tab.id === this.tab);

    //redirect users to workflow if they somehow navigate to a tab that they shouldn't be on e.g. content-type
    if (this.tab && !currentTab) {
      this.navigateTo('workflow');
      return nothing;
    }
    return html`
      <omni-style>
        <omni-toolbar class="has-background-white mb-5">
          <div class="toolbar-divider"></div>
          ${tabs.map(
            tab => html`
              <button
                slot=${tab.name === 'History' ? '' : 'start'}
                class="tab ${tab === currentTab ? 'active' : ''}"
                @click=${() => {
                  this.navigateTo(tab.id);
                }}
              >
                ${tab.name}
              </button>
            `
          )}
          ${this.#toolbarActionsTemplate}
        </omni-toolbar>
        <div class="toolbar-spacer"></div>

        ${this.#renderBreadCrumbs()}
        ${this.isLoading
          ? html`<omni-loading-indicator class="big"></omni-loading-indicator>`
          : nothing}

        <div
          class="ml-5 mr-5 ${this.isLoading ? 'under-loading-indicator' : ''}"
        >
          <slot
            ${ref(this.slotRef)}
            @breadcrumb-refresh=${() => this.requestUpdate()}
            @breadcrumb-pop=${this.#breadCrumbPop}
          ></slot>
        </div>
      </omni-style>
    `;
  }
}

customElements.define('app-layout', AppLayout);
