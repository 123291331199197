import {
  OmniElement,
  OmniStyle,
  OmniIcon,
  OmniLoadingIndicator,
  html,
  css,
} from 'omni-ui';

import { om2alert } from 'omni-campaign-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { api } from '../helpers/api.js';
import { ContextConsumer, workflowContext } from '../helpers/context.js';
import './custom-themes-color-pickers.js';
import './custom-themes-preview.js';
import './color-picker.js';
import './components/left-nav.js';
import './components/step-card.js';
import './components/empathy-map.js';
import '@simonwep/pickr/dist/themes/classic.min.css';

OmniStyle.register();
OmniIcon.register();
OmniLoadingIndicator.register();

/**
 * Class for Custom Themes Element
 * @extends OmniElement
 */
export default class CustomThemes extends OmniAppContainerMixin(OmniElement) {
  /**
   * Custom Themes Lit Properties
   */
  static get properties() {
    /**
     * Custom Themes Preview Lit Properties
     * @property {Object} theme - Stores the theme that omni-ui > omni-theme uses to apply themes; key is variable name, value is RGB value
     * @property {String} clientId - The client ID to be passed to the API so objects are scoped to the client
     * @property {String} clientName - The pretty name of the client
     * @property {String} workflowId - The framework ID to be passed to the API so objects are scoped to the proper framework
     * @property {Boolean} hasUnsavedData - used to determine state of save button
     * @property {Object} currentTheme - Copy of theme used for reset theme comparison
     * @property {Object} defaultTheme - Default omni-ui > omni-theme object
     */
    return {
      theme: { type: Object },
      clientId: this.routeParamProperty({ name: 'clientId' }),
      workflowId: this.routeParamProperty({ name: 'workflowId' }),
      hasUnsavedData: { type: Boolean },
      currentTheme: { type: Object },
      defaultTheme: { type: Object, attribute: false },
      clientName: { state: true },
      workflowData: { state: true },
    };
  }

  /**
   * Custom Themes styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        .bottom-content {
          background-color: rgb(var(--rgb-border));
          box-shadow: 0px -14px 35px -7px rgb(var(--rgb-border));
          padding: 1.79rem 2.86rem;
          position: sticky;
          z-index: 5;
          bottom: 0;
        }
        .theme-components {
          margin-bottom: 7.29rem;
        }
      `,
    ];
  }

  /**
   * @description Create CustomThemes Element and initialize {@link #hasUnsavedData}, and {@link #defaultTheme} properties
   */
  constructor() {
    super();
    this.hasUnsavedData = false;
    this.defaultTheme = {
      '--rgb-primary': '0, 161, 210',
      '--rgb-secondary': '3, 187, 227',
      '--rgb-accent-one': '77, 197, 252',
      '--rgb-accent-two': '130, 209, 171',
      '--rgb-accent-three': '255, 166, 0',
      '--rgb-accent-four': '212, 80, 135',
    };
  }

  get breadcrumb() {
    return {
      label: this.clientName ? `${this.clientName} Theming` : undefined,
      link: `workflow/${this.workflowId}/theme/${this.clientId}`,
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.currentTheme = this.theme;

    this.workflowConsumer = new ContextConsumer(this, {
      context: workflowContext,
      subscribe: true,
      callback: value => {
        // Store in a property since we may not know the clientId yet
        this.workflowData = value;
      },
    });
  }

  async updated(changedProperties) {
    if (changedProperties.has('clientId') && this.clientId) {
      const allClients = await api.listClients();
      const clientLookup = Object.fromEntries(
        allClients.map(c => [c.id, c.name])
      );
      clientLookup['*'] = '* (Default)';
      this.clientName = clientLookup[this.clientId];
      this.dispatchNewEvent('breadcrumb-refresh');
    }
    if (
      (changedProperties.has('workflowData') ||
        changedProperties.has('clientId')) &&
      this.workflowData &&
      this.clientId
    ) {
      const { theme_object: themeObject = null } =
        this.workflowData.settings?.find(
          setting => setting.uuid_client === this.clientId
        ) || {};
      this.theme = themeObject;
      this.currentTheme = this.theme;
      this.dispatchNewEvent('breadcrumb-refresh');
    }
  }

  /**
   * @description Triggers page reload
   * @fires dispatchNewEvent#reload
   */
  reload() {
    this.dispatchNewEvent('reload');
  }

  /**
   * @description Navigates user back to framework-template detail page
   * @fires dispatchNewEvent#breadcrumb-pop
   */
  cancel() {
    this.dispatchNewEvent('breadcrumb-pop');
  }

  /**
   * @description Saves theme data to a framework template on a specific client
   * calls @see {@link module:api.updateFrameworkTemplateSettings}
   * If save is successful, resets {@link #hasUnsavedData} to false
   */
  async save() {
    try {
      await api.updateFrameworkTemplateSettings(
        this.workflowId,
        this.clientId,
        {
          theme_object: this.theme,
        }
      );
      this.dispatchNewEvent('theme-updated');
      om2alert('Theme saved', { type: 'success' });
    } catch (e) {
      om2alert(e);
      return;
    }
    this.currentTheme = this.theme;
    this.hasUnsavedData = false;
  }

  /**
   * @description Updates {@link #theme} object on color picker/input change
   * Switches {@link #hasUnsavedData} to true to denote that there is data to be saved
   * @listens module:custom-themes~event:color-change
   * @param {string} key - Name of omni-ui color variable
   * @param {string} color - RGB value of color
   */
  _updateColor = ({ detail: { key, color } }) => {
    // Default theme_object === null; check to see if user has reset theme variable to default color in which case the theme won't save the color
    if (this.defaultTheme[key] === color) {
      const copy = { ...this.theme };
      delete copy[key];
      // If the updated theme has no keys, store it as null
      this.theme = Object.keys(copy).length ? copy : null;
      return;
    }
    // check to see if color has actually changed
    if (this.currentTheme?.[key] === color) {
      return;
    }
    this.theme = {
      ...this.theme,
      [key]: color,
    };
    this.hasUnsavedData = true;
  };

  /**
   * @description Resets theme to default empty object
   * @listens module:custom-themes~event:reset-theme
   * @param {Event} e - The event object that optionally contains a theme to "reset to". If no such object is supplied, the theme is reset to null.
   * Logic check after reset to see if save button needs to be enabled
   */
  _resetTheme = e => {
    this.theme = e?.detail?.theme ?? null;
    if (!this.currentTheme && !this.theme) {
      this.hasUnsavedData = false;
    } else {
      this.hasUnsavedData = true;
    }
  };

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    if (!this.clientId) {
      return html`<omni-loading-indicator></omni-loading-indicator>`;
    }
    return html` <omni-style>
      <div class="custom-themes-container">
        <div class="theme-components">
          <custom-themes-color-pickers
            .theme=${this.theme}
            @color-change=${this._updateColor}
            @reset-theme=${this._resetTheme}
          ></custom-themes-color-pickers>
          <custom-themes-preview
            .theme=${this.theme}
            .defaultTheme=${this.defaultTheme}
          ></custom-themes-preview>
        </div>
        <div class="bottom-content">
          <div class="buttons is-right">
            <button @click=${this.cancel} class="tertiary mr-4">Cancel</button>

            <button @click=${this.save} ?disabled=${!this.hasUnsavedData}>
              Save
            </button>
          </div>
        </div>
      </div>
    </omni-style>`;
  }
}

customElements.define('custom-themes', CustomThemes);
