import { OmniElement, OmniStyle, OmniIcon, html } from 'omni-ui';
import { Om2Table } from 'omni-campaign-ui';
import { api } from './helpers/api.js';
import { showLoader } from './helpers/util.js';
import { OmniAppContainerMixin } from 'omni-app-container';

OmniStyle.register();
OmniIcon.register();
Om2Table.register();

export default class ModuleWorkflowList extends OmniAppContainerMixin(
  OmniElement
) {
  /**
   * @property {String} moduleId - The id of the module (from the route)
   * @property {Object} data - Array of workflows
   * @property {Boolean} showAll - Determines whether inactive ft are shown
   * @property {Object} module - The full module object for moduleId
   */
  static get properties() {
    return {
      moduleId: this.routeParamProperty({ name: 'moduleId' }),

      // Internal
      data: { type: Array },
      showAll: { type: Boolean },
      module: { type: Object },
    };
  }

  constructor() {
    super();
    this.data = [];
    this.showAll = true;
  }

  // Load the the module object and list of workflows when we get a module id
  async updated(changed) {
    if (changed.has('moduleId') && this.moduleId) {
      this.module = await showLoader(this, api.getModule(this.moduleId));
      this.dispatchNewEvent('breadcrumb-refresh');
      this.reload();
    }
  }

  get breadcrumb() {
    return {
      label: this.module ? `${this.module.name} - Workflows` : undefined,
      toolbarActions: {},
    };
  }

  async reload() {
    this.data = await showLoader(
      this,
      api.listFrameworkTemplates({
        moduleId: this.module.uuid_module,
        fields: ['uuid_framework_template', 'name', 'description', 'active'],
      })
    );
  }

  renderModuleWorkflows() {
    const activeWorkflow = this.showAll
      ? this.data
      : this.data.filter(ft => ft.active);
    return html` <omni-style>
      <om2-table
        shadowed
        autosort
        autotooltip
        .search=${['name']}
        .columns=${[
          {
            label: 'name',
            key: 'name',
            isSortable: true,
            isMain: true,
          },
          {
            label: 'description',
            key: 'description',
            isSortable: true,
          },
          {
            label: 'active',
            key: 'active',
            isSortable: true,
            template: val =>
              html`<td>
                ${val
                  ? html`<omni-icon
                      icon-id="omni:informative:check"
                    ></omni-icon>`
                  : ''}
              </td>`,
          },
        ]}
        .data="${activeWorkflow}"
        .keyFn=${({ uuid_framework_template: id }) => id}
      >
        <span slot="header-start">${this.module?.name} - Workflows</span>
        <span slot="header-end" class="is-flex is-align-items-center">
          <omni-switch
            .checked=${this.showAll}
            @change=${() => {
              this.showAll = !this.showAll;
            }}
          ></omni-switch>
          <span class="ml-2">Show All</span>
        </span>
      </om2-table>
    </omni-style>`;
  }

  render() {
    return html`<slot>${this.renderModuleWorkflows()}</slot>`;
  }
}

customElements.define('module-workflow-list', ModuleWorkflowList);
