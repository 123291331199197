import { OmniElement, OmniStyle, OmniIcon, html, nothing } from 'omni-ui';
import { OmniAppContainerMixin } from 'omni-app-container';
import { api } from './helpers/api.js';
import { showLoader } from './helpers/util.js';
import './module-modal.js';
import './helpers/image-modal.js';
import { om2FormModal, om2alert, om2confirm } from 'omni-campaign-ui';

OmniStyle.register();
OmniIcon.register();

export default class ModuleAssistAgent extends OmniAppContainerMixin(
  OmniElement
) {
  static get properties() {
    return {
      moduleId: this.routeParamProperty({ name: 'moduleId' }),
      fieldId: this.routeParamProperty({ name: 'fieldId' }),

      clientAgents: { state: true },
      clientLookup: { state: true },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.clients = await showLoader(this, api.listClients());
    this.clients.push({ id: '*', name: '* (Default)' });
    this.clientLookup = Object.fromEntries(
      this.clients.map(c => [c.id, c.name])
    );
  }

  get unusedClients() {
    const used = new Set();
    this.clientAgents?.forEach(agent => used.add(agent.uuid_client));
    return this.clients.filter(client => !used.has(client.id));
  }

  get breadcrumb() {
    const label = this.fieldId ?? '';
    const toolbarActions = { Add: () => this.updateClientAgent() };
    return { label, toolbarActions };
  }

  async updated(changed) {
    if (
      (changed.has('moduleId') || changed.has('fieldId')) &&
      this.moduleId &&
      this.fieldId
    ) {
      this.refresh();
    }
  }

  async refresh() {
    this.clientAgents = await showLoader(
      this,
      api.listModuleAssistFieldAgents(this.moduleId, this.fieldId)
    );
  }

  async updateClientAgent(toModify = undefined) {
    om2FormModal({
      title: `${toModify ? 'Edit' : 'Add'} Client Agent`,
      data: toModify,
      onSubmit: form => {
        const clientId = form.uuid_client;
        delete form.uuid_client;
        return api.updateModuleAssistFieldAgent(
          this.moduleId,
          this.fieldId,
          clientId,
          form
        );
      },
      onSuccess: () => {
        om2alert(
          `Your client agent has been ${toModify ? 'updated' : 'added'}`,
          {
            type: 'success',
            toast: true,
          }
        );
        this.refresh();
      },
      schema: {
        uuid_client: {
          title: 'Client',
          required: true,
          type: 'string',
          hasSearch: true,
          enum: toModify ? this.clients : this.unusedClients,
          disabled: !!toModify,
        },
        name: {
          title: 'Agent',
          required: true,
          type: 'string',
          asyncEnum: async client => {
            const agents = await (client === '*'
              ? api.listGlobalAgents()
              : api.listAgents(client));
            if (!agents.length)
              om2alert('No agents found', { type: 'warning', toast: true });
            return agents;
          },
          enumURL: '%:uuid_client%',
          hasSearch: true,
        },
      },
    });
  }

  async deleteClientAgent(agent) {
    await om2confirm(
      `Are you sure you want to delete "${agent.name}" agent for client "${this.clientLookup[agent.uuid_client]}"?`,
      {
        callback: async () => {
          await api.deleteModuleAssistFieldAgent(
            this.moduleId,
            this.fieldId,
            agent.uuid_client
          );
          await this.refresh();
        },
        type: 'warning',
      }
    );
  }

  render() {
    if (!this.clientAgents || !this.clientLookup) return nothing;

    return html`
      <omni-style>
        <om2-table
          autosort
          autotooltip
          shadowed
          .columns=${[
            {
              label: 'client',
              key: 'uuid_client',
              isSortable: true,
              isMain: true,
              passthrough: true,
              template: row => html`
                <td>${this.clientLookup[row.uuid_client]}</td>
              `,
            },
            {
              label: 'agent',
              key: 'name',
              isSortable: true,
            },
            {
              label: 'actions',
              passthrough: true,
              template: row => html`
                <td>
                  <button
                    @click="${() => this.updateClientAgent(row)}"
                    class="icon"
                    title="Edit"
                  >
                    <omni-icon icon-id="omni:interactive:edit"></omni-icon>
                  </button>

                  <button
                    @click=${() => this.deleteClientAgent(row)}
                    class="icon ml-4 ${row.uuid_client === '*'
                      ? 'is-hidden'
                      : ''}"
                    title="Delete"
                  >
                    <omni-icon icon-id="omni:interactive:delete"></omni-icon>
                  </button>
                </td>
              `,
            },
          ]}
          .data=${this.clientAgents}
        >
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('module-assist-agent', ModuleAssistAgent);
