import { OmniElement, OmniStyle, OmniIcon, html } from 'omni-ui';
import * as brandIcons from 'omni-ui/icon/iconset-brand.js';
import * as objectIcons from 'omni-ui/icon/iconset-object.js';
import * as appIcons from 'omni-ui/icon/iconset-app.js';
import { om2confirm, om2FormModal, Om2Table } from 'omni-campaign-ui';
import { omniApps } from './helpers/omni-objects.js';
import { api } from './helpers/api.js';

OmniStyle.register();
OmniIcon.register();
Om2Table.register();

export default class StepAppLinks extends OmniElement {
  constructor() {
    super();
    this.addEventListener('doAdd', () => this.addAppLink());

    this.thirdPartyIcons = [
      ...Object.keys(appIcons).map(icon => ({
        id: `omni:app:${icon}`,
        name: `${icon.charAt(0).toUpperCase()}${icon.slice(1)}`,
      })),
      ...Object.keys(brandIcons).map(icon => ({
        id: `omni:brand:${icon}`,
        name: `${icon.charAt(0).toUpperCase()}${icon.slice(1)}`,
      })),
      ...Object.keys(objectIcons).map(icon => ({
        id: `omni:object:${icon}`,
        name: `${icon.charAt(0).toUpperCase()}${icon.slice(1)}`,
      })),
    ].sort((a, b) => a.name.localeCompare(b.name));
  }

  static get properties() {
    return {
      data: { type: Array },
      uuid: { type: String },
    };
  }

  reload() {
    this.dispatchNewEvent('reload');
  }

  #renderDropdownOption = (option, onlyText) => {
    return onlyText
      ? option.name
      : html`
          <omni-icon
            icon-id="${option.icon_class ?? option.id}"
            class="is-size-1 mr-4"
          ></omni-icon>
          <span>${option.name}</span>
        `;
  };

  #renderDropdownSelected = (self, options) => {
    return options.length === 0
      ? html`<span style="opacity:0.3">Select an icon</span>`
      : options.map(
          option => html`
            <div class="is-flex">
              <omni-icon
                icon-id="${option.icon_class ?? option.id}"
                class="is-size-1 mr-4"
              >
              </omni-icon
              ><span>${option.name}</span>
            </div>
          `
        );
  };

  async addAppLink() {
    om2FormModal({
      title: 'Add App Link',
      onSubmit: async data => {
        const payload = {};
        if (data.app_type === 'omni') {
          payload.name = omniApps[data.omni_app].name;
          payload.url = omniApps[data.omni_app].url;
          payload.icon_class = omniApps[data.omni_app].icon_class;
        } else {
          payload.name = data.name;
          payload.url = data.url;
          payload.icon_class = data.icon_class;
        }
        if (data.display_order) {
          payload.display_order = data.display_order;
        }
        if (this.data.find(app => app.url === payload.url)) {
          throw new Error('This app link already exists in the step');
        }
        await api.addStepAppLink(this.uuid, payload);
      },
      onSuccess: () => this.reload(),
      schema: {
        app_type: {
          title: 'App Type',
          type: 'string',
          enum: [
            { id: 'omni', name: 'Omni App' },
            { id: 'thirdparty', name: 'Third Party' },
          ],
          default: 'omni',
          required: true,
          formModal: { fullWidth: true },
        },
        omni_app: {
          title: 'Omni App',
          type: 'string',
          hasSearch: true,
          enum: omniApps
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((app, i) => ({ ...app, id: i })),
          renderSelected: this.#renderDropdownSelected,
          renderOption: this.#renderDropdownOption,
          required: true,
          formModal: {
            fullWidth: true,
            hideIf: { id: 'app_type', op: '!==', value: 'omni' },
          },
        },
        name: {
          title: 'Name',
          type: 'string',
          tooltip: 'Shown in the app link tooltip',
          required: true,
          formModal: {
            hideIf: { id: 'app_type', op: '!==', value: 'thirdparty' },
          },
        },
        url: {
          title: 'URL',
          type: 'string',
          required: true,
          formModal: {
            hideIf: { id: 'app_type', op: '!==', value: 'thirdparty' },
          },
        },
        icon_class: {
          title: 'Icon',
          type: 'string',
          hasSearch: true,
          enum: this.thirdPartyIcons,
          renderSelected: this.#renderDropdownSelected,
          renderOption: this.#renderDropdownOption,
          default: 'omni:app:thirdParty',
          formModal: {
            hideIf: { id: 'app_type', op: '!==', value: 'thirdparty' },
          },
        },
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
          default: this.data.length + 1,
        },
      },
    });
  }

  async editAppLink(app) {
    let omniAppIndex = omniApps.findIndex(a => a.url === app.url);
    if (omniAppIndex === -1) omniAppIndex = undefined;

    // Similar to addAppLink() we need to have two forms (one for omni apps and one for
    // third party apps) with some conditional logic that switches between them. This
    // form is a little more complicated as depending on whether the app being edited is
    // an omni app or a third party app we may or may not initialize the input fields.
    // Specifically if an omni app is being modified and they switch it to being a 3rd
    // party app it doesn't make sense to populate the name, url, and icon with values
    // from the omni app.

    om2FormModal({
      title: 'Edit App Link',
      onSubmit: async data => {
        const payload = {};
        if (data.app_type === 'omni') {
          const appIndex = data.omni_app ?? omniAppIndex;
          payload.name = omniApps[appIndex].name;
          payload.url = omniApps[appIndex].url;
          payload.icon_class = omniApps[appIndex].icon_class;
        } else {
          payload.name = data.name_3p;
          payload.url = data.url_3p;
          payload.icon_class = data.icon_class_3p;
        }
        if (data.display_order) {
          payload.display_order = data.display_order;
        }

        await api.updateStepAppLink(this.uuid, app.id, payload);
      },
      onSuccess: () => this.reload(),
      schema: {
        app_type: {
          title: 'App Type',
          type: 'string',
          enum: [
            { id: 'omni', name: 'Omni App' },
            { id: 'thirdparty', name: 'Third Party' },
          ],
          default: omniAppIndex !== undefined ? 'omni' : 'thirdparty',
          required: true,
          formModal: { fullWidth: true },
        },
        omni_app: {
          title: 'Omni App',
          type: 'string',
          hasSearch: true,
          enum: omniApps.map((a, i) => ({ ...a, id: i })),
          renderSelected: this.#renderDropdownSelected,
          renderOption: this.#renderDropdownOption,
          required: true,
          default: omniAppIndex, // undefined if third party
          formModal: {
            fullWidth: true,
            hideIf: { id: 'app_type', op: '!==', value: 'omni' },
          },
        },
        name_3p: {
          title: 'Name',
          type: 'string',
          tooltip: 'Shown in the app link tooltip',
          required: true,
          default: omniAppIndex === undefined ? app.name : '',
          formModal: {
            hideIf: { id: 'app_type', op: '!==', value: 'thirdparty' },
          },
        },
        url_3p: {
          title: 'URL',
          type: 'string',
          required: true,
          default: omniAppIndex === undefined ? app.url : '',
          formModal: {
            hideIf: { id: 'app_type', op: '!==', value: 'thirdparty' },
          },
        },
        icon_class_3p: {
          title: 'Icon',
          type: 'string',
          hasSearch: true,
          enum: this.thirdPartyIcons,
          renderSelected: this.#renderDropdownSelected,
          renderOption: this.#renderDropdownOption,
          default:
            omniAppIndex === undefined ? app.icon_class : 'omni:app:thirdParty',
          formModal: {
            hideIf: { id: 'app_type', op: '!==', value: 'thirdparty' },
          },
        },
        display_order: {
          title: 'Display Order',
          type: 'number',
          min: 1,
          multipleOf: 1,
          default: app.display_order,
        },
      },
    });
  }

  async removeAppLink(app) {
    const callback = async () => {
      await api.deleteStepAppLink(this.uuid, app.id);
      this.reload();
    };

    om2confirm(`Remove app link "${app.name}"?`, {
      callback,
      type: 'warning',
    });
  }

  render() {
    return html`
      <omni-style>
        <om2-table
          autosort="display_order,asc"
          autotooltip
          shadowed
          .search=${['name']}
          .columns=${[
            {
              label: 'name',
              key: 'name',
              isSortable: true,
              isMain: true,
            },
            {
              label: 'order',
              key: 'display_order',
              isSortable: true,
            },
            {
              label: 'url',
              key: 'url',
              isSortable: true,
            },
            {
              label: 'icon',
              key: 'icon_class',
              isSortable: true,
              template: icon => html`
                <td>
                  <omni-icon icon-id="${icon}" title="${icon}"></omni-icon>
                </td>
              `,
            },
            {
              label: 'actions',
              passthrough: true,
              template: app => html`
                <td>
                  <button
                    @click=${() => this.editAppLink(app)}
                    class="icon"
                    title="Edit"
                  >
                    <omni-icon icon-id="omni:interactive:edit"></omni-icon>
                  </button>
                  <button
                    @click=${() => this.removeAppLink(app)}
                    class="icon ml-4"
                    title="Remove"
                  >
                    <omni-icon icon-id="omni:interactive:remove"></omni-icon>
                  </button>
                </td>
              `,
            },
          ]}
          .data="${this.data}"
        >
          <slot name="header-start" slot="header-start"></slot>
          <slot name="header-end" slot="header-end"></slot>
          ${!this.data || this.data.length === 0
            ? html`
                <div class="has-text-centered p-5" slot="body">
                  <i>
                    ${this.data
                      ? 'There are currently no app links.'
                      : 'Loading...'}
                  </i>
                </div>
              `
            : ''}
        </om2-table>
      </omni-style>
    `;
  }
}

customElements.define('step-app-links', StepAppLinks);
