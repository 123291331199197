import { OmniElement, OmniStyle, OmniIcon, html, css } from 'omni-ui';
import { om2alert } from 'omni-campaign-ui';
import { sleep } from '../helpers/util.js';

OmniStyle.register();
OmniIcon.register();

/**
 * Class for Custom Themes Color Pickers Element
 * @extends OmniElement
 */
export default class CustomThemesColorPickers extends OmniElement {
  /**
   * Custom Themes Color Picker Lit Properties
   * @property {Object} theme - Stores the theme that omni-ui > omni-theme uses to apply themes; key is variable name, value is RGB value
   * @property {Object} defaultTheme - Default omni-ui > omni-theme object
   */
  static get properties() {
    return {
      theme: { type: Object },
      defaultTheme: { attribute: false },
    };
  }

  /**
   * Custom Themes Color Picker styles
   */
  static get styles() {
    return [
      super.styles,
      css`
        omni-tile {
          margin-bottom: 2.143rem;
        }
        .color-selection-wrapper {
          margin-left: 2.286rem;
          margin-bottom: 4.286rem;
        }
        omni-tile.color-selection-wrapper::part(body-slot) {
          padding: 2.143rem 2.857rem 1.429rem 0;
        }
        .color-picker-container {
          display: flex;
          margin-left: 0.75rem;
          gap: 2.143rem;
          flex-wrap: wrap;
          align-items: flex-end;
        }
        .omni .subheader {
          margin: 2.821rem 0.857rem 1.714rem 0.75rem;
        }
        .subheader-wrapper {
          display: flex;
          align-items: center;
        }
      `,
    ];
  }

  static get validThemeKeys() {
    return [
      '--rgb-primary',
      '--rgb-secondary',
      '--rgb-accent-one',
      '--rgb-accent-two',
      '--rgb-accent-three',
      '--rgb-accent-four',
    ];
  }

  static clipboard = null;

  /**
   * @description copy theme object to clipboard as json string
   * @param {event} e - The event triggering this call
   */
  async _copyTheme(e) {
    const { target } = e;
    try {
      target.classList.add('is-loading');
      this.constructor.clipboard = JSON.stringify(this.theme, null, 2);
    } catch (ex) {
      om2alert('Writing theme to clipboard failed');
      throw ex;
    } finally {
      await sleep(500);
      target.classList.remove('is-loading');
      this.requestUpdate(); // paste button may become enabled
    }
  }

  /**
   * @description Set theme object to clipboard value (if it is valid)
   * @param {event} e - The event triggering this call
   */
  async _pasteTheme(e) {
    const { target } = e;
    try {
      target.classList.add('is-loading');
      const parsed = JSON.parse(this.constructor.clipboard);
      // Error checking to make sure things look right
      let error;
      Object.entries(parsed).forEach(([key, value]) => {
        const colors = value.split(', ').map(x => parseFloat(x));
        if (!this.constructor.validThemeKeys.includes(key)) {
          error = `Found invalid theme key "${key}" in clipboard`;
        } else if (
          colors.length !== 3 ||
          !colors.every(c => c >= 0 && c <= 255)
        ) {
          error = `Invalid color: "${value}"`;
        }
      });
      if (error) {
        om2alert(error);
        return;
      }
      this.dispatchNewEvent('reset-theme', { detail: { theme: parsed } });
    } catch (ex) {
      om2alert('Reading theme from clipboard failed');
      throw ex;
    } finally {
      await sleep(500);
      target.classList.remove('is-loading');
    }
  }

  /**
   * @description Renders color picker components to page
   * @param {string} type - primary or accent; determines placement of color picker
   * {@link #defaultPalette} is a dictionary that contains default omni-ui variable names as keys
   * Color - RGB value by default of each omni-ui variable
   * Type - primary or accent; determines location of color picker when rendered
   * Label - Label name used to describe color variable
   */
  _renderColorPickers = type => {
    const defaultPalette = {
      '--rgb-primary': {
        color: '0, 161, 210',
        type: 'primary',
        label: 'primary color',
      },
      '--rgb-secondary': {
        color: '3, 187, 227',
        type: 'primary',
        label: 'secondary color',
      },
      '--rgb-accent-one': {
        color: '77, 197, 252',
        type: 'accent',
        label: 'accent color one',
      },
      '--rgb-accent-two': {
        color: '130, 209, 171',
        type: 'accent',
        label: 'accent color two',
      },
      '--rgb-accent-three': {
        color: '255, 166, 0',
        type: 'accent',
        label: 'accent color three',
      },
      '--rgb-accent-four': {
        color: '212, 80, 135',
        type: 'accent',
        label: 'accent color four',
      },
    };
    return Object.entries(defaultPalette)
      .filter(([, value]) => value.type === type)
      .map(
        ([key, value]) =>
          html` <div>
            <label class="subtitle mr-1 mb-2 question-label"
              >${value.label}</label
            >
            <color-picker
              .color=${this.theme?.[key] ?? value.color}
              .colorVarName=${key}
            ></color-picker>
          </div>`
      );
  };

  /**
   * @returns {html} Rendered HTML for the component
   */
  render() {
    const primaryTooltip =
      'Primary color controls the color theme. The combination of Primary and Secondary colors will create a gradient on some UI elements.';
    const accentTooltip =
      'Accent colors will change the underline colors of stage names.';
    return html`<omni-style>
      <omni-tile class="module-wrapper">
        <div class="color-selection-wrapper">
          <h1 class="title mb-0 is-2">Customize Themes</h1>
          <div class="subheader-wrapper">
            <h2 class="subheader has-text-weight-bold">Main Colors</h2>
            <omni-tooltip>
              <omni-icon
                slot="invoker"
                class="is-size-2 is-clickable"
                icon-id="omni:informative:info"
              ></omni-icon>
              <div slot="content">${primaryTooltip}</div>
            </omni-tooltip>
          </div>
          <div class="color-picker-container">
            ${this._renderColorPickers('primary')}
          </div>
          <div class="subheader-wrapper">
            <h2 class="subheader has-text-weight-bold">Accent Colors</h2>
            <omni-tooltip>
              <omni-icon
                slot="invoker"
                class="is-size-2 is-clickable"
                icon-id="omni:informative:info"
              ></omni-icon>
              <div slot="content">${accentTooltip}</div>
            </omni-tooltip>
          </div>
          <div class="color-picker-container">
            ${this._renderColorPickers('accent')}
          </div>
          <div class="buttons mt-6 ml-3">
            <button
              class="secondary mr-4"
              ?disabled=${Object.keys(this.theme ?? {}).length === 0}
              @click=${() => this.dispatchNewEvent('reset-theme')}
            >
              Reset Theme
            </button>
            <button
              class="secondary mr-4"
              ?disabled=${Object.keys(this.theme ?? {}).length === 0}
              @click=${e => this._copyTheme(e)}
            >
              Copy Theme
            </button>
            <button
              class="secondary"
              @click=${e => this._pasteTheme(e)}
              ?disabled=${!this.constructor.clipboard}
            >
              Paste Theme
            </button>
          </div>
        </div>
      </omni-tile>
    </omni-style>`;
  }
}

customElements.define('custom-themes-color-pickers', CustomThemesColorPickers);
