let idCounter = 1;

export const showLoader = async (node, fnOrPromise) => {
  // Store the target element to send events. If we send the events from node, some of
  // our events might get lost if the node is removed from the dom before it's sent.
  const target = node.closest('app-layout');
  const loaderId = idCounter++;
  try {
    target.dispatchNewEvent('app-layout-loading', { detail: { id: loaderId } });
    const result = fnOrPromise.then ? await fnOrPromise : await fnOrPromise();
    return result;
  } finally {
    target.dispatchNewEvent('app-layout-ready', { detail: { id: loaderId } });
  }
};

const sleep = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export { sleep };

export const hasRole = role =>
  window.AN.sso.user.projects
    .find(project => project.typeKey === 'campaign_admin')
    ?.userRoles?.includes(role);
